import type { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import type {
  IRefreshMessageData,
  IUpdateMessageData
} from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes'
import { defineStore } from 'pinia'
import { contentTypes, type WebSpotlightRootModel } from '~/models'
interface PageState {
  page: IContentItem | null
  linkedItems: IContentItemsContainer
}

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    page: null,
    linkedItems: {} as IContentItemsContainer
  }),
  actions: {
    async fetchData(itemId: string) {
      const response = await $fetch('/api/page/' + itemId)
      if (response) {
        //@ts-ignore
        this.page = response.data.item as IContentItem
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
        //   this.dataLoaded = true
      }
    },
    async fetchHome() {
      const response = await $fetch('/api/home')
      if (response) {
        //@ts-ignore
        this.page = response.data.item as WebSpotlightRootModel
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
      }
    },
    updateItemData(data: IUpdateMessageData) {
      if (!this.page) return
      const targetLinkedItem = this.linkedItems[data.item.codename]

      if (targetLinkedItem) {
        for (let i = 0; i < data.elements.length; i++) {
          const element = data.elements[i]
          const type = element.type
          const newValue = element.data.value
          const propertyName = camelCasePropertyName(element.element.codename)
          if (type === 'text') {
            targetLinkedItem.elements[propertyName].value = newValue
          }
        }
      }
    },
    async refreshItemData(data: IRefreshMessageData) {
      const itemId = this.page?.system.id
      if (itemId) await this.fetchData(itemId)
    }
  },
  getters: {
    getLinkedItem:
      (state) =>
      <T>(codename: string) => {
        return state.linkedItems?.[codename] as T
      },
    pageTitle: (state) => {
      if (!state.page) return ''

      return state.page.elements.title.value
    },
    getListAs:
      (state) =>
      <T>(codenames: string[]) => {
        if (codenames && codenames.length > 0) {
          return codenames.map((codename) => state.linkedItems?.[codename] as T)
        }

        return []
      }
  }
})

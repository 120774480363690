<template>
  <NuxtLoadingIndicator />
  <SmartLinkProvider>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </SmartLinkProvider>
</template>
<script lang="ts" setup>

const siteStore = useSiteStore()
await siteStore.fetchData()

</script>
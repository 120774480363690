export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', () => {
    const siteStore = useSiteStore()
    let scripts: Array<string> = []
    if (siteStore.fetched) {
      for (const script of siteStore.scriptTags) {
        const value = removeScriptTag(script.elements.innerCode.value)
        scripts.push(value)
      }

      useHead(() => {
        return {
          script: scripts
        }
      })
    }
  })
})
function removeScriptTag(value: string) {
  return value.replace(/<script.*?>|<\/script>/g, '')
}

import { contentTypes } from '~/models'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const path = to.path
  const siteStore = useSiteStore()
  const pageStore = usePageStore()
  await siteStore.fetchData()
  const url = useRequestURL()
  const sourceUrl = url.href.replace('https://', '').replace('http://', '')
  // const externalRedirectFound = siteStore.externalRedirects.find(
  //   (x) => x.elements.sourceUrl.value.trim() === sourceUrl
  // )

  //todo: look for all previous urls history
  // if (externalRedirectFound) {
  //   console.log(externalRedirectFound)
  //   const redirectType =
  //     externalRedirectFound.elements.redirectType.value[0].codename === 'permanent' ? 301 : 302
  //   return navigateTo(externalRedirectFound.elements.targetUrl.value, {
  //     external: true,
  //     redirectCode: redirectType
  //   })
  // }
  const page = siteStore.getPage(path, to.query.itemid as string)
  if (page) {
    await pageStore.fetchData(page.itemid)
    if (page.redirectTo && page.redirectTo.length > 0) {
      return navigateTo(page.redirectTo, {
        external: page.redirectTo.startsWith('http'),
        redirectCode: 302
      })
    } 
    // else if (page.type === contentTypes.folder.codename) {
    //   return abortNavigation()
    // }
  }
})

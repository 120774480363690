import type { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import {
  contentTypes,
  type ScriptTagModel,
  type SiteConfigurationModel
} from '~/models'
import type { KontentPageRoute } from '~/types/KontentPageRoute'

interface SiteState {
  siteConfig: SiteConfigurationModel
  fetched: boolean
  linkedItems: IContentItemsContainer
  pages: KontentPageRoute[]
}

export const useSiteStore = defineStore('site', {
  state: (): SiteState => ({
    siteConfig: {} as SiteConfigurationModel,
    fetched: false,
    linkedItems: {} as IContentItemsContainer,
    pages: [] as KontentPageRoute[]
  }),
  actions: {
    async fetchData() {
      if (!this.fetched) {
        const siteResponse = await $fetch('/api/site-configuration')
        const pagesResponse = await $fetch('/api/navigation/pages')
        //@ts-ignore
        if (siteResponse.data) {
          //@ts-ignore
          this.siteConfig = siteResponse.data.item as SiteConfigurationModel
          //@ts-ignore
          this.linkedItems = siteResponse.data.linkedItems as IContentItemsContainer
        }
        //@ts-ignore
        if (pagesResponse.data) {
          //@ts-ignore
          this.pages = pagesResponse.data as KontentPageRoute[]
        }

        this.fetched = true
      }
    },
    async refreshSiteData() {
      await this.fetchData()
    },

  },
  getters: {
    scriptTags: (state) => {
      const tags: ScriptTagModel[] = []

      if (state.fetched) {
        for (const item of state.siteConfig.elements.scriptTags.value) {
          const linkedItem = state.linkedItems?.[item] as ScriptTagModel
          if (!linkedItem) continue
          tags.push(linkedItem)
        }
      }

      return tags
    },
    /**
     * This method returns the linked item by its codename. Only use this in siteConfig elements otherwise use pageStore.getLinkedItem
     * @param codename content type codename
     * @returns returns the linked item
     */
    getLinkedItem:
      (state) =>
        <T>(codename: string) => {
          return state.linkedItems?.[codename] as T
        },
    getUrlByCodename: (state) => (codename: string) => {
      if (!state.fetched) return ''
      const page = state.pages.find((x) => x.codename === codename)
      return page?.path ?? ''
    },
    getUrlByItemId: (state) => (itemId: string) => {
      if (!state.fetched) return ''
      const page = state.pages.find((x) => x.itemid === itemId)
      return page?.path ?? ''
    },
    getPage: (state) => (urlPath: string, itemId?: string | null) => {
      if (!state.fetched) return null
      if (itemId) {
        return state.pages.find((x) => x.itemid === itemId)
      }
      return state.pages.find((x) => x.path === urlPath)
    },
    getListAs:
      (state) =>
        <T>(codenames: string[]) => {
          if (codenames && codenames.length > 0) {
            return codenames.map((codename) => state.linkedItems?.[codename] as T)
          }

          return []
        }
    // externalRedirects: (state): RedirectRuleModel[] => {
    //   const redirects: RedirectRuleModel[] = []
    //   if (!state.fetched) return redirects

    //   for (const item of state.siteConfig.elements.externalRedirects.value) {
    //     const linkedItem = state.linkedItems?.[item] as RedirectRuleModel
    //     if (!linkedItem) continue
    //     redirects.push(linkedItem)
    //   }

    //   return redirects
    // }
  }
})

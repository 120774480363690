import KontentSmartLink, { KontentSmartLinkEvent } from '@kontent-ai/smart-link'
import type {
  IRefreshMessageData,
  IRefreshMessageMetadata,
  IUpdateMessageData
} from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes'

export function useSmartLinkProvider() {
  const config = useRuntimeConfig()
  const instance = KontentSmartLink.initialize({
    defaultDataAttributes: {
      projectId: config.public.projectId,
      languageCodename: 'default'
    }
  })


  useState<KontentSmartLink>('smartLink', () => instance)
}

export function useSmartLink() {
  const smartLink = useState<KontentSmartLink>('smartLink')
  if (!smartLink) {
    throw new Error(
      'You need to place SmartLinkProvider to one of the parent components to use useSmartLink.'
    )
  }

  return smartLink
}

export function useCustomRefresh(
  callback: (
    data: IRefreshMessageData,
    metadata: IRefreshMessageMetadata,
    originalRefresh: () => void
  ) => void
) {
  const smartLink = useSmartLink()

  if (smartLink.value) {
    smartLink.value.on(KontentSmartLinkEvent.Refresh, callback)
  }
}

export function useLivePreview(callback: (data: IUpdateMessageData) => void) {
  const smartLink = useSmartLink()

  if (smartLink.value) {
    smartLink.value.on(KontentSmartLinkEvent.Update, callback)
  }
}

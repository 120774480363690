export const camelCasePropertyName = (name: string): string => {
  return addUnderscoreWhenStarsWithNumber(removeZeroWidthCharacters(camelize(name)))
}

const camelize = (str: string): string => {
  str = str.replace(getPropertyNameRegex(), (_, c) => (c ? c.toUpperCase() : ''))
  return str.substring(0, 1).toLowerCase() + str.substring(1)
}

const getPropertyNameRegex = (): RegExp => {
  return /[+;,/&()?\-='"\\\xa7!%:_\s.]+(.)?/g
}

const removeZeroWidthCharacters = (str: string): string => {
  return str.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

const addUnderscoreWhenStarsWithNumber = (str: string): string => {
  if (startsWithNumber(str)) {
    return `_${str}`
  }
  return str
}

const startsWithNumber = (str: string): boolean => {
  if (str.match(/^\d/)) {
    return true
  }
  return false
}
